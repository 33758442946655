<template>
<v-container>
  <v-card-text>
    <v-row>
      <v-col cols="3">
        <v-btn text @click="selectedBox=undefined">
          {{ $t('delete') }}
        </v-btn>
      </v-col>
      <v-col cols="3">
        <v-combobox :label="$t('box-base')" v-model="selectedBox" :items="getUserList"/>
      </v-col>
    </v-row>
  </v-card-text>

  <v-card-text>
    <v-row>
      <v-col cols="3">
        <v-btn text @click="beginDate='2000-01'; endDate=getCurrentDate()">
          {{ $t('delete') }}
        </v-btn>
      </v-col>
      
      <v-col cols="3">
        <v-date-picker v-model="beginDate" type="month" show-current="false" color="green"/>
      </v-col>
      
      <v-col cols="3">
        <v-date-picker v-model="endDate" type="month" show-current="false" color="blue"/>
      </v-col>
      
    </v-row>
  </v-card-text>

  <v-card-text>
    <v-row>
      <v-col cols="3">
        <v-btn text @click="patientIDBegin='';patientIDEnd=''">
          {{ $t('delete') }}
        </v-btn>
      </v-col>
      <v-col cols="3">
        <v-text-field type="number" :label="$t('patient-begin')" v-model="patientIDBegin" />
      </v-col>
      <v-col cols="3">
        <v-text-field type="number" :label="$t('patient-end')" v-model="patientIDEnd" />
      </v-col>
    </v-row>
  </v-card-text>
  
  <v-card-text>
    <v-row>
      <v-col cols="4">
        <v-btn elevation="4" text @click="downloadPDFReport()">
          {{ $t('PDF-report') }}
        </v-btn>
      </v-col>
      <v-col cols="4">
        <v-btn elevation="4" text @click="downloadCSVReport()">
          {{ $t('CSV-spreadsheet') }}
        </v-btn>
      </v-col>
    </v-row>
  </v-card-text>

  PatientID start -> end, inclusive interval
  start year, month -> end year month, inclusive interval

  PDF report for graphs
  CSV for raw data export

  <v-container>
  {{ selectedBox }}
  {{ beginDate }}
  {{ endDate }}
  {{ patientIDBegin }}
  {{ patientIDEnd }}
  </v-container>

</v-container>
</template>

<script>
export default {
  name: "Stats",
  data() {
    return {
      beginDate: "2000-01",
      endDate: this.getCurrentDate(),
      selectedBox : undefined,
      userList: undefined,
      patientIDBegin: "",
      patientIDEnd: ""
    }
  },
  mounted() {
    this.loadUserList();
  },
  computed: {
      getUserList() {
        if (this.userList !== undefined)
        {
          const boxList = this.userList.filter((user) => user.Role == "box");
          const result = boxList.map((user) => ({value:user.UserID, text:(user.UserID + " " + user.Name + " - " + user.Address)}));
          return result;
        }
        else
        {
          return [];
        }
      }
    },
  methods: {
      async loadUserList() {
        this.userList = (await this.$store.dispatch("listUsers")).users;
      },
      getCurrentDate() {
        const today= new Date();
        return today.getFullYear()+'-'+(today.getMonth()+1);
      },
      async downloadPDFReport() {
        var params = {}
        
        params.beginDate = this.beginDate;
        params.endDate = this.endDate;
        
        if (this.selectedBox)
        {
          params.targetUserID = this.selectedBox["value"];
        }
        
        if (this.patientIDBegin || this.patientIDEnd)
        {
          params.beginIndex = this.patientIDBegin;
          params.endIndex = this.patientIDEnd;
        }
        
        console.log(params);
        
        var response = await this.$store.dispatch("pdfStats", params);
        console.log(response);
        
        var pdfURL = response.link;
        
        try {
          
          response = await fetch(pdfURL);
          var pdfBlob = await response.blob();
          
          const data = window.URL.createObjectURL(pdfBlob);
          var link = document.createElement('a');
          
          link.href = data;
          link.download = this.$t("stats") + ".pdf";
          link.onclick = function(){window.URL.revokeObjectURL(pdfBlob);}
          link.click();
        } catch (err)
        {
          window.open(pdfURL);
        }
        
      },
      async downloadCSVReport() {
        var params = {}
        
        params.beginDate = this.beginDate;
        params.endDate = this.endDate;
        
        if (this.selectedBox)
        {
          params.targetUserID = this.selectedBox["value"];
        }
        
        if (this.patientIDBegin || this.patientIDEnd)
        {
          params.beginIndex = this.patientIDBegin;
          params.endIndex = this.patientIDEnd;
        }
        
        console.log(params);
        
        var response = await this.$store.dispatch("csvStats", params);
        console.log(response);
        
        var pdfURL = response.link;
        
        try {
          
          response = await fetch(pdfURL);
          var pdfBlob = await response.blob();
          
          const data = window.URL.createObjectURL(pdfBlob);
          var link = document.createElement('a');
          
          link.href = data;
          link.download = this.$t("stats") + ".csv";
          link.onclick = function(){window.URL.revokeObjectURL(pdfBlob);}
          link.click();
        } catch (err)
        {
          window.open(pdfURL);
        }
        
      }
    }
};
</script>
